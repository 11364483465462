<template>
    <div class="live-events full-height full-width">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <template v-if="activeComps && activeComps.length">
                <v-container fluid class="pa-6 overflow-y-scroll full-height full-width p-relative">
                    <v-row>
                        <v-col class="section-header d-flex align-start justify-space-between" cols="12">
                            <div class="d-flex flex-column mr-6">
                                <h3 class="mb-3">Competitions</h3>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="2"
                                    v-for="competition in activeComps"
                                    :key="competition._id"
                                >
                                    <basic-tile
                                        :item="competition"
                                        @click="
                                            $router.push({
                                                name: 'live-competitions-id',
                                                params: { competition: competition._id },
                                            })
                                        "
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-else>
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="d-flex flex-column align-center justify-center full-height">
                        <p class="text-center mb-0">There are no events happening right now.</p>
                    </div>
                </v-container>
            </template>
        </template>
    </div>
</template>

<script>
// Packes
import moment from "moment";

// Services
import Competition from "@/modules/app-module/competition";

// Components
import BasicTile from "@/components/global/basic-tile.vue";

export default {
    name: "live-competitions",

    meta: {
        title: "Live Competitions",
    },

    props: {},

    components: {
        BasicTile,
    },

    data() {
        return {
            loading: false,
            competitions: [],
        };
    },

    computed: {
        activeComps() {
            let competitions = this.competitions;

            competitions = competitions.filter((competition) => {
                let isOnline = competition?.data?.judgingType == "live";

                let judging = competition?.data?.judging;
                let judgingClosed = judging.closed;
                let judgingStartDate = moment(judging.startDate).format("YYYY-MM-DD");
                let judgingEndDate = moment(judging.endDate).format("YYYY-MM-DD");
                let currentDate = moment().format("YYYY-MM-DD");

                // Current date is in between judging start and end dates. It is also not marked as "closed"
                let judgingOpen = currentDate >= judgingStartDate && currentDate < judgingEndDate && !judgingClosed;

                return isOnline && judgingOpen;
            });

            return competitions;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            try {
                // const query = {
                //     _type: "article",
                //     definition: "competition",
                //     "data.judgingType": "live",
                //     "data.judging.closed": {
                //         $ne: true,
                //     },
                //     "data.judging.startDate": {
                //         $lte: "date('now')",
                //     },
                //     // "data.judging.endDate": {
                //     //     $gte: "date('now')",
                //     // },
                // };

                //Changed to custom query to improve loadtime
                const competitionQuery = {
                    status: "active",
                    definition: "competition",
                    "data.judgingType": "live",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                };
                this.competitions = await Competition.query(competitionQuery).then(({ data }) => data);

                console.log("COMPETITIONS", this.competitions);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
